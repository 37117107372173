exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-canceled-js": () => import("./../../../src/pages/canceled.js" /* webpackChunkName: "component---src-pages-canceled-js" */),
  "component---src-pages-confirmed-js": () => import("./../../../src/pages/confirmed.js" /* webpackChunkName: "component---src-pages-confirmed-js" */),
  "component---src-pages-display-options-js": () => import("./../../../src/pages/displayOptions.js" /* webpackChunkName: "component---src-pages-display-options-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-not-served-js": () => import("./../../../src/pages/notServed.js" /* webpackChunkName: "component---src-pages-not-served-js" */),
  "component---src-pages-on-checkout-js": () => import("./../../../src/pages/onCheckout.js" /* webpackChunkName: "component---src-pages-on-checkout-js" */),
  "component---src-pages-select-weight-js": () => import("./../../../src/pages/selectWeight.js" /* webpackChunkName: "component---src-pages-select-weight-js" */)
}

